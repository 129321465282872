<template>
  <ValidationProvider
    :name="$t('accounting.movement.type')"
    :rules="required ? 'required' : ''"
    slim
    vid="accountingmovementtype"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="accountingMovementTypeId"
        :error-messages="errors"
        :items="items"
        :label="hideLabel ? undefined : $t('accounting.movement.type')"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :multiple="multiple"
        :success="valid"
        :disabled="disabled"
        dense
        flat
        hide-details
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import type { AccountingMovementTypeData } from "@planetadeleste/vue-mc-gw";
import { AccountingMovementTypeCollection } from "@planetadeleste/vue-mc-gw";
import {
  chain,
  cloneDeep,
  concat,
  filter,
  forEach,
  isArrayLike,
  isEmpty,
  map,
  set,
  startsWith,
} from "lodash";

@Component
export default class AccountingMovementTypeSelect extends Vue {
  @VModel({ type: [Number, String, Array] }) accountingMovementTypeId!:
    | number
    | number[]
    | string
    | string[];
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly withAll!: boolean;
  @Prop(Array) readonly disabledItems!: number[];
  @Prop(Array) readonly onlyItems!: number[];
  @Prop(String) readonly marketType!: "sell" | "buy";
  @Prop(Boolean) readonly omitMarketType!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;

  loading = false;
  obCollection: AccountingMovementTypeCollection =
    new AccountingMovementTypeCollection();

  get models() {
    return this.obCollection.getModelList();
  }

  get items() {
    if (!this.models.length) {
      return [];
    }

    let arList = isEmpty(this.onlyItems)
      ? this.models
      : (filter(this.models, (obItem) => {
          return this.onlyItems.includes(obItem.id);
        }) as AccountingMovementTypeData[]);

    if (this.marketType) {
      arList = filter(arList, (obItem: AccountingMovementTypeData) => {
        const sCode = this.marketType === "buy" ? "COMPRA" : "VENTA";
        return this.omitMarketType
          ? !obItem.code.includes(sCode)
          : obItem.code.includes(sCode);
      });
    }

    if (this.withAll) {
      forEach(this.itemAll, (obItem) => {
        arList.push(obItem);
      });
    }

    return map(arList, (obItem: AccountingMovementTypeData) => {
      let bDisabled =
        this.disabledItems && this.disabledItems.includes(obItem.id);

      if (
        (this.multiple &&
          isArrayLike(this.accountingMovementTypeId) &&
          this.accountingMovementTypeId.length &&
          // @ts-ignore
          this.accountingMovementTypeId.includes(obItem.id)) ||
        (!this.multiple && obItem.id === this.accountingMovementTypeId)
      ) {
        bDisabled = false;
      }

      set(obItem, "disabled", bDisabled);

      return obItem;
    }) as Partial<AccountingMovementTypeData>[];
  }

  get itemAll() {
    if (!this.obCollection.length) {
      return [];
    }

    const arSellList = chain(cloneDeep(this.models))
      .filter((obItem) => startsWith(obItem.code, "VENTA"))
      .map((obItem, idx, arItems) => {
        obItem.id = map(arItems, "id");
        obItem.name = this.$t("all.sell");
        return obItem;
      })
      .head()
      .value();

    const arBuyList = chain(cloneDeep(this.models))
      .filter((obItem) => startsWith(obItem.code, "COMPRA"))
      .map((obItem, idx, arItems) => {
        obItem.id = map(arItems, "id");
        obItem.name = this.$t("all.buy");
        return obItem;
      })
      .head()
      .value();

    return concat([{ divider: true }], arSellList, arBuyList, [
      {
        name: this.$t("all"),
        id: -1,
      },
    ]);
  }

  onSelect(sValue: number) {
    const obModel = this.obCollection.find({ id: sValue });

    this.$emit("input", sValue);
    this.$emit("change", obModel);
  }

  async mounted() {
    this.loading = true;
    const obCollection = new AccountingMovementTypeCollection();
    const obResponse = await obCollection.list();
    this.obCollection.clear();
    this.obCollection.add(obResponse.getData().data);
    this.loading = false;
  }
}
</script>
